<template>
    <button
        type="button"
        :class="classes"
        :style="style"
        :disabled="disabled || loading"
        @click="$emit('onClick')"
    >
        <LoadingSpinner v-if="loading" class="btn__loader" :variant="(variant == 'outlined') ? 'dark' : 'light'" />
        <slot>{{ label }}</slot>
    </button>
</template>

<script lang="ts">
 import { computed } from '#imports'
export default {
    emits: ['onClick'],
    props: {
        label: { type: String, default: null },
        variant: { type: String, default: "primary" },
        size: { type: String, default: "md" },
        backgroundColor: { type: String, default: null },
        color: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        rounded: { type: Boolean, default: true },
        iconOnly: { type: Boolean, default: false },
    },
    setup(props) {
        const classes = computed(() => {
            return {
                btn: true,
                ["btn--" + props.variant]: true,
                ["btn--size-" + props.size]: true,
                ["btn--color-" + props.color]: true,
                "btn--disabled": props.disabled,
                "btn--rounded": props.rounded,
                "btn--icon-only": props.iconOnly,
                "btn--loading": props.loading,
            };
        });

        const style = computed(() => {
            return {
                backgroundColor: props.backgroundColor,
                color: props.color,
            };
        });

        return {
            classes,
            style,
        };
    },
};


</script>

<style lang="scss" scoped>
@import "@/assets/css/base/mixins";
.btn {
    line-height: 1;
    border-radius: .25rem;
    padding: .75rem 2rem;
    font-weight: 600;
    border: 2px solid var(--brand-neutral);
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    position: relative;
    white-space: nowrap;
    flex-shrink: 0;
    @include hover {
    }
    @include ellipsis;
    display: inline-flex;
    &--rounded {
        border-radius: 1.5rem;
    }
    &--icon-only {
        padding: 0.5rem;
        height: 2.5rem;
        width: 2.5rem;
        &.btn--size-sm {
            padding: 0.25rem;
            height: 2.25rem;
            width: 2.25rem;
        }
    }
    &--primary {
        background-color: var(--brand-neutral);
        color: #ffffff;
        border-color: transparent;
        @include hover {
            opacity: .9;
        }
    }
    &--success {
        background-color: var(--green-base);
        color: #ffffff;
        border-color: transparent;
    }
    &--neutral {
        background-color: transparent;
        color: var(--brand-neutral);
        border-color: transparent;
    }
    &--outlined {
        background-color: transparent;
        color: var(--brand-neutral);
        border: 2px solid var(--brand-neutral);
        &.btn--color-light {
            color: #ffffff;
            border: 2px solid #ffffff;
        }
    }
    &--neutral-outlined {
        color: var(--gray-700);
        border-color: var(--gray-200);
        background-color: #ffffff;
        @include shadow("xs");
        @include hover {
            background-color: var(--gray-100);
        }
        &--warning-neutral {
            background-color: transparent;
            color: var(--status-danger);
            border-color: transparent;
        }
        &--size- {
            &xs {
                padding: 0.3125rem 0.5rem;
            }
            &sm {
                padding: 0.75rem 1rem;
                height: 2.5rem;
            }
        }
    }
    &--grayed {
        background-color: transparent;
        color: var(--gray-800);
        font-weight: 600;
        border-color: transparent;
    }
    &--dark {
        background-color: var(--gray-800);
        color: var(--gray-50);
        border-color: var(--gray-800);
    }
    &--warning {
        color: #ffffff;
        border-color: var(--status-danger);
        background-color: var(--status-danger);
        font-weight: 600;
        @include hover {
            opacity: 0.9;
        }
    }
    &--disabled {
        color: #ffffff;
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.4;
    }
    &--warning-outlined {
        color: var(--status-danger);
        border-color: var(--red-lighter);
        background-color: #ffffff;
        font-weight: 600;
        @include hover {
            opacity: 0.9;
        }
    }
    &--warning-neutral {
        color: var(--status-danger);
        border-color: transparent;
        background-color: transparent;
        font-weight: 600;
        @include hover {
            border-color: var(--status-danger);
        }
    }
    &--size- {
        &xs {
            padding: 0.3125rem 0.5rem;
        }
        &sm {
            padding: 0.75rem 1rem;
            height: 2.5rem;
        }
    }
    .spinner-circle {
        position: absolute;
        left: 0.5rem;
    }
    .icon {
        flex-shrink: 0;
    }
    &--loading {
        padding-left: 1.25rem;
        pointer-events: none;
        opacity: .8;
    }
    &__loader {
        margin-right: .5rem;
    }

    &.shared {
        border: 3px solid var(--green-base);
    }
}
</style>
