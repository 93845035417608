<template>
<div class="spinner" :class="{['spinner--'+variant]: variant, ['spinner--size-'+size]: size}">
  <div class="double-bounce1"></div>
  <div class="double-bounce2"></div>
</div>
</template>
    
<script>
    export default {
        props: {
            variant: {
                type: String,
                default: 'light'
            },
            size: {
              type: String,
              default: 'sm'
            }
        },
        setup() {

            return {

            }
        }
    }
</script>
<style lang="scss" scoped>
.spinner {
  width: 1rem;
  height: 1rem;

  position: relative;
  &--size- {
    &lg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.spinner--dark {
    .double-bounce1, .double-bounce2 {
        background-color: #000;
    }
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
</style>

<style lang="scss">
.base-input__wrap .spinner {
  position: absolute;
  right: .75rem;
  top: .75rem;
}
</style>